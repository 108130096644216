import React from "react";
import axios from "axios";
import * as Config from "../config";
import arrow from "../../assets/img/icon/arrow.png";
import Swal from "sweetalert2";

class TopFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      faq: [],
    };
  }

  getFaq = async () => {
    const response = await axios.get(`${Config.BASE_URL}/faqs`);
    try {
      this.setState({
        faq: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };
  componentDidMount() {
    this.getFaq();
  }

  render() {
    return (
      <div className="faq">
        {this.state.faq.map((item) => (
          <div key={item.id}>
            <div
              className="about-us-top"
              style={{
                backgroundImage: `url(${Config.BASE_URL}${item.gambar[0].url})`,
              }}
            >
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
            <div className="container capt">
              <div className="card">
                <div className="content card-body">
                  <h5>{item.judul}</h5>
                  <p>{item.caption} </p>
                </div>
              </div>
            </div>
            <div className="container">
              {item.tanyajawab.map((faq) => (
                <div
                  className="accordion md-accordion"
                  id="accordionEx"
                  role="tablist"
                  aria-multiselectable="true"
                  style={{ marginBottom: 2 + `%` }}
                >
                  <div className="card">
                    <div className="card-header" role="tab" id={faq.id}>
                      <a
                        data-toggle="collapse"
                        data-parent="#accordionEx"
                        href={`#collapse${faq.id}`}
                        aria-expanded="true"
                        aria-controls={faq.id}
                      >
                        <h5 class="mb-0">
                          {faq.pertanyaan}
                          <i style={{ float: "right" }}>
                            <img src={arrow}></img>
                          </i>
                        </h5>
                      </a>
                    </div>
                    <div
                      id={`collapse${faq.id}`}
                      class="collapse"
                      role="tabpanel"
                      aria-labelledby={`collapse${faq.id}`}
                      data-parent="#accordionEx"
                    >
                      <div className="card-body">{faq.jawaban}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default TopFaq;
