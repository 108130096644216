import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import BG from "../../assets/img/BG/bg-gallery.png";
import axios from "axios";
import Swal from "sweetalert2";
import * as Config from "../config";

const images = [];
class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  getGallery = async () => {
    const response = await axios.get(`${Config.BASE_URL}/galleries`);
    try {
      var respon = response.data;
      for (var i = 0; i < respon.length; i++) {
        response.data[i].photo.forEach((val) => {
          images[i] = {
            original: Config.BASE_URL + val.url,
            thumbnail: Config.BASE_URL + val.formats.thumbnail.url,
          };
        });
      }
    } catch (error) {
      Swal.fire(error.message);
    }
  };

  componentDidMount() {
    this.getGallery();
  }

  render() {
    return (
      <div className="Gallery" style={{ backgroundImage: `url(${BG})` }}>
        <div className="container">
          <p>Galeri PIMTI Perempuan</p>
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            largeHeap={true}
          />
        </div>
      </div>
    );
  }
}
export default Gallery;
