import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Pagination from "../components/pagination/PaginationComponent";
import ListArticle from "../components/pagination/ListArticle";
import * as Config from "../../src/components/config";
import TopNews from "../components/informasi/topNews";

class Informasi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      loading: false,
      currentPage: 1,
      postsPerPage: 5,
      kategoris: [],
    };
  }

  getPosts = async () => {
    this.setState({ loading: true });
    const results = await axios.get(`${Config.BASE_URL}/informasis`);
    this.setState({ posts: results.data });
    this.setState({ loading: false });
  };
  getKategoris = async () => {
    this.setState({ loading: false });
    const results = await axios.get(`${Config.BASE_URL}/kategori-informasis`);
    this.setState({ kategoris: results.data });
    this.setState({ loading: false });
  };
  componentDidMount() {
    this.getPosts();
    this.getKategoris();
  }

  render() {
    const { currentPage, postsPerPage, posts, loading, kategoris } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNum) => this.setState({ currentPage: pageNum });

    const nextPage = () => this.setState({ currentPage: currentPage + 1 });

    const prevPage = () => this.setState({ currentPage: currentPage - 1 });

    return (
      <>
        <TopNews></TopNews>
        <ListArticle
          posts={currentPosts}
          loading={loading}
          kategoris={kategoris}
        />
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={posts.length}
          paginate={paginate}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </>
    );
  }
}

export default withRouter(Informasi);
