import React from "react";
import ImageContactUs from "../../assets/img/contact-us2.png";
class ContactUs extends React.Component {
  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <p>Hubungi Kami</p>
          </div>
          <div className="row justify-content-center img-side">
            <div className="col-5 pt-50">
              <div className="row">
                <img src={ImageContactUs}></img>
              </div>
            </div>
            <div
              className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <form onSubmit={this.handleSubmit} class="php-email-form">
                <div className="form-group mt-3">
                  <label for="name">Nama*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nama"
                    id="nama"
                    placeholder="Masukan Nama Anda"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="organisasi">Organisasi</label>
                  <input
                    type="text"
                    className="form-control"
                    name="organisasi"
                    id="organisasi"
                    placeholder="Masukan Nama Organisasi Anda"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="email">E-mail*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Masukan e-mail Anda"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="handphone">Nomor Hp*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="handphone"
                    id="handphone"
                    placeholder="Masukan e-mail Anda"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="pesan">Pesan*</label>
                  <textarea
                    className="form-control"
                    name="handphone"
                    rows="10"
                    required
                    placeholder="Masukan pesan anda di sini"
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" value="Submit">
                    Kirim
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;
