import React from "react";
import axios from "axios";
import * as Config from "../config";
import moment from "moment";
import "moment/locale/id";

class DetailArtikel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      image: null,
    };
  }

  getDetailArtikel = async () => {
    let id = window.location.pathname.replace("/DetailArtikel/", "");
    axios.get(`${Config.BASE_URL}/informasis/${id}`).then((response) => {
      try {
        this.setState({ detail: response.data });
        this.setState({ image: response.data.photo[0].url });
      } catch (error) {
        this.setState({ isLoading: false, isError: true });
      }
    });
  };
  componentDidMount() {
    this.getDetailArtikel();
  }
  render() {
    return (
      <div className="detail">
        <div className="container">
          <div className="header">
            <div key={this.state.detail.id}>
              <span>
                <h5>
                  Artikel,{" "}
                  <i>
                    {moment(this.state.detail.published_at).format(
                      "dddd, DD MMMM YYYY"
                    )}
                  </i>
                </h5>
              </span>
              <h4>{this.state.detail.judul_informasi}</h4>
              <div>
                <img
                  src={`${Config.BASE_URL}${this.state.image}`}
                  style={{ width: 100 + `%` }}
                ></img>
              </div>
              <p className="subTitle">{this.state.detail.keterangan_photo}</p>
              <div className="isi pt-3">
                <p>Sumber : {this.state.detail.sumber_informasi}</p>
                <p>Penulis : {this.state.detail.penulis_informasi}</p>
              </div>
              <div className="desc pt-3">
                <p style={{ textAlign: "justify" }}>
                  {this.state.detail.deskripsi_informasi}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailArtikel;
