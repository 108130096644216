import React, { useState, useEffect, Component } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../config";
import moment from "moment";
import "moment/locale/id";

function ListArticle({ posts, loading, kategoris }) {
  const [seacrhTerm, setSearchTerm] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  console.log(posts);

  function setCategory(item) {
    setCategoryList((prevState) => {
      let categories = JSON.parse(JSON.stringify(prevState));
      if (!categories.includes(item.id)) categories.push(item.id);
      else categories.splice(categories.indexOf(item.id), 1);
      console.log(categories);
      return categories;
    });
  }

  if (loading) {
    return <h2>Loading...</h2>;
  }
  return (
    <div className="ListArtikel">
      <div className="container">
        <h4 className="topTitle">Informasi PIMTI</h4>
        <div className="SearchBar">
          <div className="container pr-0 pl-0">
            <div className="card">
              <div className="container" method="get">
                <input
                  className="form-control"
                  id="header-search"
                  type="text"
                  placeholder="Search.."
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                ></input>
                <div id="myDIV" style={{ paddingTop: "10px" }}>
                  <h4>kategori</h4>
                  {kategoris.map((item) => (
                    <button
                      onClick={() => setCategory(item)}
                      className={`btn ${
                        categoryList.includes(item.id) ? "active" : ""
                      } mr-3 mb-3`}
                      key={item.id}
                    >
                      {item.nama}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {posts
            .filter((post) => {
              if (
                categoryList.length === 0 ||
                categoryList.includes(post.kategori_informasis[0].id)
              )
                return post;
            })
            .filter((post) => {
              if (seacrhTerm === "") {
                return post;
              } else if (
                post.judul_informasi
                  .toLowerCase()
                  .includes(seacrhTerm.toLowerCase())
              ) {
                return post;
              }
            })
            .map((post, key) => {
              return (
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="col-2" key={post.id}>
                    <Link to={`/DetailArtikel/${post.id}`}>
                      <img
                        src={`${BASE_URL}${post.photo[0].url}`}
                        className="img-fluid"
                        alt="photo "
                      />
                    </Link>
                  </div>
                  <div className="artikel col-10 p-0">
                    {post.kategori_informasis.map((item, index) => index <1 && (
                      <h5>
                        {item.nama} - {" "}
                        {moment(post.published_at).format("dddd, DD MMMM YYYY")}
                      </h5>
                    ))}
                    <a href={`/DetailArtikel/${post.id}`}>
                      <h3> {post.judul}</h3>
                    </a>
                    <p>{post.deskripsi}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ListArticle;
