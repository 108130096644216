import React from "react";
import * as Config from "../../components/config";
import ImgBackground from "../../assets/img/BG/bg-1.png";
import axios from "axios";
import Swal from "sweetalert2";

class TopAboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      about: [],
    };
  }

  getAbout = async () => {
    const response = await axios.get(`${Config.BASE_URL}/tentang-kamis`);
    try {
      this.setState({
        about: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };
  componentDidMount() {
    this.getAbout();
  }

  render() {
    return (
      <div className="main">
        {this.state.about.map((item) => (
          <>
            <div
              className="about-us-top"
              style={{
                backgroundImage: `url(${Config.BASE_URL}${item.top.photo_banner[0].url})`,
              }}
            >
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
            <div className="container capt">
              <div className="card">
                <div className="content card-body">
                  <div key={item.top.id}>
                    <h5>{item.top.judul}</h5>
                    <p>{item.top.deskripsi}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }
}

export default TopAboutUs;
