import React from "react";
import * as Config from "../config";
import axios from "axios";
import Swal from "sweetalert2";

class Sejarah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      sejarah: [],
    };
  }

  getSejarah = async () => {
    const response = await axios.get(`${Config.BASE_URL}/tentang-kamis`);
    try {
      this.setState({
        sejarah: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };
  componentDidMount() {
    this.getSejarah();
  }

  render() {
    return (
      <div className="sejarah">
        {this.state.sejarah.map((data, index) => (
          <div className="container" key={index}>
            <div className="row justify-content-center img-side">
              <div className="col-5 pt-50" style={{ placeSelf: "flex-end" }}>
                <>
                  <div
                    className="row"
                    style={{ height: 50 + "%", paddingBottom: 15 + "%" }}
                  >
                    <img
                      src={`${Config.BASE_URL}${data.sejarah.photo[0].url}`}
                    ></img>
                  </div>
                  <div className="row">
                    <img
                      src={`${Config.BASE_URL}${data.sejarah.photo[1].url}`}
                    ></img>
                  </div>
                </>
              </div>
              <div className="col-1"></div>
              <div className="col-6 mt-6 mt-lg-0 d-flex align-items-stretch">
                <div className="caption">
                  <h5 className="tittle">{data.sejarah.judul}</h5>
                  <p className="isi">{data.sejarah.deskripsi}</p>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10%" }}>
              <div className="col-5 logo" style={{ placeSelf: "center" }}>
                <div key={data.makna_logo.id}>
                  <img
                    src={`${Config.BASE_URL}${data.makna_logo.logo.url}`}
                    alt="logo pimti"
                  ></img>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-6">
                <div className="caption">
                  <div key={data.makna_logo.id}>
                    <h5 className="tittle">{data.makna_logo.judul}</h5>
                    <p className="isi">{data.makna_logo.deskripsi}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Sejarah;
