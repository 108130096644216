import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Bg3 from "../../assets/img/mask1.png";
import Arrow from "../../assets/img/icon/arrow (2).png";
import axios from "axios";
import * as Config from "../config";
import moment from "moment";
import "moment/locale/id";

class Kegiatan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
      informasi: [],
      loading: false,
    };
  }

     getInformasi = async () => {
      this.setState({ loading: false });
      const results = await axios.get(`${Config.BASE_URL}/informasis`);
      this.setState({ informasi: results.data });
      this.setState({ loading: false });
    };
   
    componentDidMount() { 
      this.getInformasi();
    }

  render() {
    return (
      <div>
        <div id="kegiatan" className="kegiatan">
          <section id="kegiatan" className="pt-0">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="kegiatan-kanan col-4"
                  style={{ backgroundImage: `url(${Bg3})` }}
                >
                  <div className="caption-kegiatan ">
                    <div className="description">
                      <h3>Informasi PIMTI</h3>
                      <p>
                        PIMTI Perempuan melakukan berbagai kegiatan mengenai :
                        pendidikan, sosial, budaya dan kemanusiaan serta
                        melakukan kerja sama dengan berbagai pihak
                      </p>
                      <a href="/Informasi">
                        {" "}
                        <i>
                          <img src={Arrow}></img>Lihat Semua
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="row news-banner ">
                    {this.state.informasi.map(
                      (item, index) =>
                        index < 1 && (
                          <div
                            className="container"
                            style={{
                              backgroundImage: ` linear-gradient(0deg, #FECBC7 1.59%, rgba(254, 203, 199, 0) 48.94%), url(${Config.BASE_URL}${item.photo[0].url})`,
                            }}
                          >
                            <div className="title-news row">
                              <>
                                <a href={`/DetailArtikel/${item.id}`}>
                                  <h4>
                                    {moment(item.published_at).format(
                                      "dddd, DD MMMM YYYY"
                                    )}{" "}
                                    <br></br>
                                    <br></br>
                                    {item.judul}
                                  </h4>
                                </a>
                              </>
                            </div>
                          </div>
                        )
                    )}
                    <div className="sub-news row">
                      <OwlCarousel
                        className="owl-theme"
                        margin={24}
                        items={3}
                        responsive={this.state.responsive}
                        nav
                      >
                        {this.state.informasi.map((item) => (
                          <div className="detail">
                            <a href={`/DetailArtikel/${item.id}`}>
                              <img
                                src={`${Config.BASE_URL}${item.photo[0].url}`}
                                alt="krgiatan"
                                key={item.id}
                              ></img>
                              <div className="details">
                                <h5 className="date">
                                  {moment(item.published_at).format(
                                    "dddd, DD MMMM YYYY"
                                  )}
                                </h5>
                                <h4 className="title">
                                  {item.judul}
                                </h4>
                              </div>
                            </a>
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Kegiatan;
