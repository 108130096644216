import React from "react";
import axios from "axios";
import * as Config from "../config";
import Swal from "sweetalert2";

class Quoets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      Quoets: [],
    };
  }

  getQuoets = async () => {
    const response = await axios.get(`${Config.BASE_URL}/tentang-kamis`);
    try {
      this.setState({
        Quoets: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };

  componentDidMount() {
    this.getQuoets();
  }

  render() {
    return (
      <div className="quoets" style={{ backgroundColor: "#F83F5E" }}>
        <div className="container">
          <div className="isi">
            {this.state.Quoets.map((data, index) => (
              <React.Fragment key={index}>
                <div>
                  <h5>{data.quotes.deskripsi}</h5>
                  <p>{data.quotes.penulis}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Quoets;
