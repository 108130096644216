import React from "react";
import BgPorto from "../../assets/img/bg-porto.png";
import axios from "axios";
import * as Config from "../config";
import Swal from "sweetalert2";
class Portofolios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      isLoading: true,
    };
  }

  getProgram = async () => {
    const response = await axios.get(`${Config.BASE_URL}/programs`);
    try {
      this.setState({
        programs: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };
  componentDidMount() {
    this.getProgram();
  }

  render() {
    return (
      <div
        id="portfolio"
        className="portfolio"
        style={{
          backgroundImage: `url(${BgPorto})`,
          backgroundBlendMode: `screen`,
        }}
      >
        <section id="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <p>Program PIMTI Perempuan</p>
            </div>
            <div
              className="row portfolio-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {this.state.programs.map((item) => (
                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-wrap">
                    <img
                      src={`${Config.BASE_URL}${item.image[0].url}`}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portofolio-text">
                      <h4>{item.caption}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Portofolios;
