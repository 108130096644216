import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Config from "../config";
import moment from "moment";
import "moment/locale/id";

class TopNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      TopNews: [],
    };
    this.props={
      
    }
  }
  getTopNews = async () => {
    try {
      const response = await axios.get(`${Config.BASE_URL}/informasis`);
      this.setState({
        isError: false,
        isLoading: false,
        TopNews: response.data,
      });
    } catch (error) {
      this.setState({ isLoading: false, isError: true });
    }
  };

  componentDidMount() {
    this.getTopNews();
  }
  render() {
    return (
      <div className="informasi" style={{ paddingTop: 5 + `%` }}>
        <div className="terbaru">
          <div className="container">
            <h4 className="topTitle">Informasi Terbaru</h4>
            <div className="row">
              {this.state.TopNews.map(
                (item, index) =>
                  index < 1 && (
                    <div
                      className="news col-7"
                      style={{
                        backgroundImage: ` linear-gradient(180deg, rgba(255, 255, 255, 0) 46.6%, #FECBC7 100%), url(${Config.BASE_URL}${item.photo[0].url})`,
                      }}
                    >
                      `${console.log(`${Config.BASE_URL}${item.photo[0].url}`)}`
                      <div className="deskripsi">
                        <div>
                          <h5 className="date">
                            {" "}
                            {moment(item.published_at).format(
                              "dddd, DD MMMM YYYY"
                            )}
                          </h5>
                          <Link to={`/DetailArtikel/${item.id}`}>
                            <h4 className="title">{item.judul}</h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
              )}
              <div className="col-5">
                {this.state.TopNews.map(
                  (artikel, index) =>
                    index > 0 &&
                    index < 4 && (
                      <div className="row" style={{ marginBottom: "15px" }}>
                        <div className="col-6">
                          <Link to={`/DetailArtikel/${artikel.id}`}>
                            <img
                              src={`${Config.BASE_URL}${artikel.photo[0].url}`}
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="artikel col-6 p-0">
                          {artikel.kategori_informasis.map((item, index) => index < 1 &&(
                            <h5>{item.nama}</h5>
                          ))}
                          <p className="tgl">
                            {moment(artikel.published_at).format(
                              "dddd, DD MMMM YYYY"
                            )}
                          </p>
                          <Link to={`/DetailArtikel/${artikel.id}`}>
                            <h4>{artikel.judul}</h4>
                          </Link>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopNews;
