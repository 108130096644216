import React from "react";
import TopAboutUs from "../components/aboutUs/top-about";
import { withRouter } from "react-router-dom";
import Quoets from "../components/aboutUs/quoets";
import Sejarah from "../components/aboutUs/sejarah";
import Gallery from "../components/aboutUs/gallery";


class AboutUs extends React.Component {
  render() {
    return (
      <>
        <TopAboutUs></TopAboutUs>
        <Quoets></Quoets>
        <Sejarah></Sejarah>
        <Gallery></Gallery>
      </>
    );
  }
}

export default withRouter (AboutUs);
