import React from "react";
import * as Config from "../config";
import axios from "axios";
import Swal from "sweetalert2";
import BgButtom from "../../assets/img/bg-buttom.png";
class ButtomBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Allkontent: [],
      isLoading: false,
    };
  }

  getContent = async () => {
    const response = await axios.get(`${Config.BASE_URL}/berandas`);
    try {
      this.setState({
        Allkontent: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };
  componentDidMount() {
    this.getContent();
  }

  render() {
    return (
      <div className="buttom-banner">
        <section
          id="buttom-banner"
          className="b-banner d-flex align-items-center pt-0 pb-0"
        >
          <div className="container-fluid">
            <div className="row">
              {this.state.Allkontent.map((item, index) => (
                <>
                  <div className="title-kiri col-lg-4 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <div className="container justify-content-center">
                      <React.Fragment key={index}>
                        <h3>{item.kontent.konten_kiri}</h3>
                      </React.Fragment>
                    </div>
                  </div>
                  <div
                    className="title-kanan col-lg-8 order-2 order-lg-1 d-flex flex-column justify-content-center"
                    style={{
                      backgroundImage: `url(${BgButtom})`,
                      backgroundBlendMode: `screen`,
                    }}
                  >
                    <div className="kanan container justify-content-center">
                      <div key={index}>
                        <h3>{item.kontent.konten_kanan}</h3>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ButtomBanner;
