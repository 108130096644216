export const BASE_URL = `https://cms.pimtiperempuan.com`;
export const BERANDA = `https://cms.pimtiperempuan.com/berandas`;
export const FAQ = `https://cms.pimtiperempuan.com/faqs`;
export const FOOTER = `https://cms.pimtiperempuan.com/footers`;
export const GALLERY = `https://cms.pimtiperempuan.com/galleries`;
export const INFORMASI = `https://cms.pimtiperempuan.com/informasis`;
export const KATEGORI_INFORMASI = `https://cms.pimtiperempuan.com/kategori-informasis`;
export const PROGRAM = `https://cms.pimtiperempuan.com/programs`;
export const TENTANG_KAMI = `https://cms.pimtiperempuan.com/tentang-kamis`;



