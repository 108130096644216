import React from "react";
import axios from "axios";
import * as Config from "../config";
import Bg from "../../assets/img/mask.png";
import Bg2 from "../../assets/img/mask2.png";
import Swal from "sweetalert2";

class Misi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tujuanmisis: [],
      isLoading: false,
    };
  }

  getMisi = async () => {
    try {
      const response = await axios.get(`${Config.BASE_URL}/berandas`);
      this.setState({
        tujuanmisis: response.data,
      });
    } catch (error) {
      Swal.fire(error.message);
    }
  };

  componentDidMount() {
    this.getMisi();
  }

  render() {
    return (
      <div className="visi" id="visi">
        <div className="container">
          {this.state.tujuanmisis.map((item, index) => (
            <React.Fragment key={index}>
              <div className="row">
                <div className="col-12">
                  <div className="container misi">
                    <div className="image-misi" id="img-misi">
                      {item.photo_beranda.map((photo) => (
                        <img
                          src={`${Config.BASE_URL}${photo.url}`}
                          alt="viismisi"
                          key={photo.id}
                          style={{ width: 100 + `%` }}
                        ></img>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="container"
                    id="title-visi"
                    style={{
                      backgroundImage: `url(${Bg})`,
                      backgroundBlendMode: `screen`,
                    }}
                  >
                    <div className="title">
                      <h3>Misi PIMTI</h3>
                    </div>
                    <div className="container caption" id="caption">
                      <p>
                        <ul>
                          <React.Fragment key={index}>
                            {item.misi.map((misii, index) => (
                              <li key={index}>{misii.isi}</li>
                            ))}
                          </React.Fragment>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="container"
                    id="title-visi"
                    style={{
                      backgroundImage: `url(${Bg2})`,
                      backgroundBlendMode: `screen`,
                    }}
                  >
                    <div className="title">
                      <h3>Maksud & Tujuan PIMTI</h3>
                    </div>
                    <div className="container caption" id="caption">
                      <p>
                        <ol>
                          <React.Fragment>
                            {item.tujuan.map((tujuan) => (
                              <ul>{tujuan.isi}</ul>
                            ))}
                          </React.Fragment>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default Misi;
