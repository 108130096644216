import React from "react";
import ContactUs from "./../components/home/contact-us";
import Vissions from "./../components/home/visions";
import Portofolios from "./../components/home/portofolios";
import ButtomBanner from "../components/home/butom-banner";
import Misi from "../components/home/misi";
import Kegiatan from "../components/home/kegiatan";


class Home extends React.Component {
  render() {
    return (
      <>
        <Vissions></Vissions>
        <ButtomBanner></ButtomBanner>
        <Misi></Misi>
        <Kegiatan></Kegiatan>
        <Portofolios></Portofolios>
        <ContactUs></ContactUs>
      </>
    );
  }
}

export default Home;
