import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Home from "./page/home";
import Navigation from "./components/navbar";
import Footer from "./components/footer";
import AboutUs from "./page/about-us";
import Faq from "./page/faq";
import Informasi from "./page/informasi";
import DetailArtikel from "./components/informasi/detailArtikel";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Navigation></Navigation>
        <Switch>
          <Route path="/AboutUs">
            <AboutUs></AboutUs>
          </Route>
          <Route path="/Faq">
            <Faq></Faq>
          </Route>
          <Route path="/Informasi">
            <Informasi></Informasi>
          </Route>
          <Route path="/DetailArtikel/:id">
            <DetailArtikel></DetailArtikel>
          </Route>
          <Route path="/">
            <Home></Home>
          </Route>
        </Switch>
        <Footer></Footer>
      </Router>
    );
  }
}

export default App;
