import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Config from "./config";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: [],
      isLoading: false,
    };
  }

  getFooter = async () => {
    try {
      const response = await axios.get(`${Config.BASE_URL}/footers`);
      this.setState({
        isError: false,
        isLoading: false,
        footer: response.data,
      });
    } catch (error) {
      this.setState({ isLoading: false, isError: true });
    }
  };

  componentDidMount() {
    this.getFooter();
  }

  render() {
    return (
      <footer id="footer">
        {this.state.footer.map((item, index) => (
          <React.Fragment key={index}>
            <div className="container">
              <div className="row footer">
                <div className="col-sm-4">
                  <div className="left">
                    <div className="logo">
                      <img
                        src={`${Config.BASE_URL}${item.logo.url}`}
                        key={item.key}
                        style={{ maxWidth: 70 + `%` }}
                      ></img>
                      <div className="caption"> {item.deskripsi}</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2" id="sitemap">
                  <div className="middle">
                    <h5>SITEMAP</h5>
                    <div className="link">
                      <Link to="/">Beranda</Link>
                    </div>
                    <div className="link">
                      <Link to="/Informasi">Informasi</Link>
                    </div>
                    <div className="link">
                      <a href="/#visi"> Misi</a>
                    </div>
                    <div className="link">
                      <Link to="/#portfolio">Program</Link>
                    </div>
                    <div className="link">
                      {" "}
                      <Link to="/about-us">Tentang Kami</Link>
                    </div>
                    <div className="link">
                      {" "}
                      <Link to="/Faq">Tanya &amp; Jawab</Link>
                    </div>
                    <div className="link">
                      <a href="/#contact"> Hubungi Kami</a>
                    </div>
                    <div className="link">
                      {" "}
                      <a href="http://salamibu.com/" target="_blank">
                        Salamibu.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-3" id="kontak">
                  <div className="middle">
                    <h5>Kontak</h5>
                    {item.sosmed.map((sosmed) => (
                      <>
                        <div className="link-icon">
                          <div class="icon-link">
                            <a
                              key={sosmed.id}
                              href={sosmed.link}
                              target={sosmed.target}
                            >
                              <div className="contact-us d-flex">
                                <div className="icon m-0">
                                  {" "}
                                  <img
                                    src={`${Config.BASE_URL}${sosmed.icon[0].url}`}
                                    alt="icon"
                                  ></img>{" "}
                                </div>
                                <div className="title-link">
                                  {sosmed.nama_akun}
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright container-fluid">
              <div className="copyright">
                &copy; 2021 PIMTI Perempuan, some right reserved
              </div>
            </div>
          </React.Fragment>
        ))}
      </footer>
    );
  }
}

export default Footer;
