import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import Logo from "../../src/assets/img/logo.png";
class Navigation extends React.Component {
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        fixed="top"
        class="fixed-top d-flex align-items-center"
      >
        <Container className="d-flex align-items-center justify-content-between">
          <Navbar.Brand href="/">
            <img
              src={Logo}
              width="72"
              height="72"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto">
              <Nav.Link href="/">Beranda</Nav.Link>
              <Nav.Link href="/Informasi">Informasi</Nav.Link>
              <Nav.Link href="/#visi">Misi</Nav.Link>
              <Nav.Link href="/#portfolio">Program</Nav.Link>
              <Nav.Link href="/AboutUs">Tentang Kami</Nav.Link>
              <Nav.Link href="/Faq">Tanya &amp; Jawab</Nav.Link>
              <Nav.Link href="/#contact">Hubungi Kami</Nav.Link>
              <Nav.Link href="http://salamibu.com/" target="_blank">
                Salamibu.com
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Navigation;
