import React from "react";
import { withRouter } from "react-router-dom";
import TopFaq from "../components/faq/topFaq";

class Faq extends React.Component {
  render() {
    return (
    <TopFaq></TopFaq>
    );
  }
}

export default withRouter(Faq);
