import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Icon from "./../../assets/img/icon/Vector.png";
import BgBanner from "./../../assets/img/Group1.png";
import * as Config from "../config";

class Visions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
      beranda: [],
    };
  }

  getBeranda = async () => {
      const results = await axios.get(`${Config.BASE_URL}/berandas`);
      this.setState({ beranda: results.data });
      this.setState({ loading: false });
    };

  componentDidMount() {
    this.getBeranda();
  }

  render() {
    return (
      <section
        className="herotop d-flex align-items-center"
        id="hero"
        style={{ backgroundImage: `url(${BgBanner})` }}
      >
        <div className="top-banner container-fluid ">
          <div className="row gy-4">
            <div className="col-lg-8 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <div className="container">
                <h1>PIMTI</h1>
                <h2>Perempuan Indonesia</h2>
                <span>
                  <ul>
                    <li>
                      {" "}
                      <img src={Icon} alt="icon"></img> Layanan
                    </li>
                    <li>
                      {" "}
                      <img src={Icon} alt="icon"></img> Pelatihan
                    </li>
                    <li>
                      {" "}
                      <img src={Icon} alt="icon"></img> Konsultasi dan Advokasi
                    </li>
                    <li>
                      {" "}
                      <img src={Icon} alt="icon"></img> Kemanusiaan
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div className="side-image col-lg-4 order-1 order-lg-2 hero-img">
              <OwlCarousel
                items={1}
                className="owl-theme"
                margin={5}
                align-items="center"
              >
                {this.state.beranda.map((item) => (
                  <div key={item.id}>
                    <img
                      src={`${Config.BASE_URL}${item.banner.gambar[0].url}`}
                      alt="banner"
                    ></img>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Visions;
